import * as React from "react";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import SEO from "../components/seo";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils";
import Form from '../components/forms/unsubscribe-form';

const Unsubscribe = () => {
    return (
        <div className="wrapper wrapper-static">
            <SEO title="" />
            <Header />
            <section className="unsubscribe-section">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={8} lg={10} xs={11}>
                            <div className="page_404 text-center">
                                <h1>Unsubscribe?</h1>
                                <p>Decided not to move or got inbox overload and just want to manage what you receive, no problem! Confirm your email below and we will stop sending you updates.</p>
                                <Form />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default Unsubscribe;
