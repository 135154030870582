/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, } from "gatsby";
import { useLocation } from "@reach/router";

function SEO({ description, lang, meta, title, image }) {
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
        `
    )

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata?.title;

    const location = useLocation();

  var { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home" )
    pathname = "/"

  if (pathname == "/search/" || pathname == "/search" )
    pathname = "/property/for-sale/in-south-buckinghamshire/"

  if (pathname == "/about/" || pathname == "/about" )
    pathname = "/about/our-team/"

  if (pathname == "/property/for-sale/" || pathname == "/property/for-sale" )
    pathname = "/property/for-sale/in-south-buckinghamshire/"

  if (pathname == "/property/to-rent/" || pathname == "/property/to-rent" )
    pathname = "/property/to-rent/in-south-buckinghamshire/"

  var curentUrl = '';
  curentUrl = process.env.GATSBY_SITE_URL+pathname;

  // set no index for result pagination
  let hasPagination = false;
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split('page-');
    hasPagination = pageStr[1] || false;
    if (pageStr[0] !== undefined) {
     curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
    }
  }

  if (!curentUrl.endsWith('/'))
        curentUrl = curentUrl + '/';

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
  //set lowercase url
  curentUrl = curentUrl.toLowerCase();

    let metaItems = meta;


    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            link={[
            {
              href: curentUrl,
              rel: "canonical"
            }]}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `og:url`,
                    content: curentUrl,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `viewport`,
                    content: "width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
                },
                {
                    name: `og:image`,
                    content: image
                }
            ].concat(metaItems)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
